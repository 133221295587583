<template>
  <div>

    <CRow>
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="contract_managers">
          Contract Manager List
        </h1>
        <CCard class="">
          <CCardHeader>
            <div class="card-header-actions">
              <router-link :to="{name: 'contract_manager.new'}" v-if="hasPerm('contract_manager.create')">
                <CButton block color="primary" v-translate translate-context="list.actions">Create</CButton>
              </router-link>
            </div>
          </CCardHeader>
          <CCardBody class="px-0">
            <CDataTable
                :hover="false"
                :bordered="true"
                :items="contract_managers.results"
                :fields="fields"
                :column-filter="{external: true,lazy:true}"
                :column-filter-value="filters"
                :sorter="{external: true, resetable:true}"
                :loading="loading"
                :sorter-value="sorting"
                @update:column-filter-value="updateFilters"
                @update:sorter-value="updateSorting"
                class="custom-table custom-table--clean custom-table--has-filters"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h5 v-translate>No items found</h5>
                </div>
              </template>
              <template #sorting-icon="{ state, classes }">
                <div :class="classes">
                  <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                  <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                </div>
              </template>
              <template #actions="{item}">
                <td class="text-right">
                  <CButton variant="outline" color="success" class="mr-2"
                           @click="$router.push({name: 'contract_manager.detail', params: {id: item.id}})"
                           v-translate translate-context="list.actions"
                           v-if="hasPerm('contract_manager.contract.list')"
                  >
                    Contracts
                  </CButton>
                  <CButton variant="outline" color="primary" class="mr-2"
                           @click="$router.push({name: 'contract_manager.edit', params: {id: item.id}})"
                           v-translate translate-context="list.actions"
                           v-if="hasPerm('contract_manager.update')"
                  >
                    Edit
                  </CButton>
                  <CButton @click="onDelete(item.id)" variant="outline" color="danger"
                           v-translate translate-context="list.actions"
                           v-if="hasPerm('contract_manager.delete')"
                  >
                    Delete
                  </CButton>
                </td>
              </template>
              <template #under-table>
                <CPagination
                    v-show="contract_managers.pagination.num_pages > 1"
                    :activePage="contract_managers.pagination.page"
                    @update:activePage="updatePage"
                    :pages="contract_managers.pagination.num_pages"
                />
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>


    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ListContractManager',

  data() {
    return {
      loading: true,
      fields: [
        {key: 'id', label: this.$pgettext('contract_managers.list', 'ID'), _style: 'width:100px;'},
        {key: 'name', label: this.$pgettext('contract_managers.list', 'Name')},
        {key: 'region', label: this.$pgettext('contract_managers.list', 'Region')},
        {key: 'actions', label: this.$pgettext('contract_managers.list', 'Actions'), sorter: false, filter: false}
      ],
      pagination: {
        page_size: 20,
        page: 1,
      },
      sorting: {
        column: null,
        asc: false
      },
      filters: {},
    }
  },
  async mounted() {
    await Promise.all([
      this.fetchConfig({type: 'contract_manager'}),
      this.fetchList()
    ])
  },
  computed: {
    ...mapGetters('contractManager', [
      'contract_managers',
    ]),
  },
  methods: {
    ...mapActions('contractManager', [
      'fetchContractManagerList',
      'deleteContractManager'
    ]),
    ...mapActions('login', [
      'fetchConfig'
    ]),
    async fetchList() {
      this.loading = true
      await this.fetchContractManagerList({
        filters: this.filters,
        pagination: this.pagination,
        sorting: this.sorting
      })
      this.pagination.page = this.contract_managers.pagination.page
      this.loading = false
    },
    updateFilters(filters) {
      this.filters = filters
      this.pagination.page = 1
      this.fetchList()
    },
    updateSorting(sorting) {
      this.sorting = sorting
      this.fetchList()
    },
    updatePage(page) {
      this.pagination.page = page
      this.fetchList()
    },
    onDelete(id) {
      this.confirm({
        title: this.$gettext('Delete contract_manager'),
        message: this.$gettext('Are you sure you want to delete this contract_manager?'),
        cb_confirm: async () => {
          await this.deleteContractManager(id)
          await this.fetchList()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>